import React, { Component } from 'react';
import './MusicLander.css';

class MusicLander extends Component {
  render() {
    return(
      <div className='music-aligner'>coming soon: music</div>
    );
  }
}

export default MusicLander;