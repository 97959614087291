import React, { Component } from 'react';

class CatAsciiXSmall extends Component {
  render() {
    return(
      <span>{`
😺`}</span>
    );
  }
}

export default CatAsciiXSmall;