import React, { Component } from 'react';

class CatAsciiSmall extends Component {
  render() {
    return(
      <span>{`
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@,@ @@@@@@@@ @@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@  @@@@@/ @@&@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@ @@@  @@@  @@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@(@@       (@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@  @@@   @@@   @@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@  @@@  &,@@  @@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@*             &@@@@@%  &@@@@@@@@@@
@@@@@@@@@@@#@@@@  @@@@@@@@   @@@@       @@@@@@@@@
@@@@@@@@@@@@@@@@@(  ,(##   @@@@.   @@   @@@@@@@@@
@@@@@@@@@@@@@@@@@@@(      @@@@(  ,@@@   @@@@@@@@@
@@@@@@@@@@@@@@@@@@@        &@@   @@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@          &,  @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@              @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@              @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@              @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@  *      (    @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@    @   %    @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@      *   &    @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@     @         @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@  @  &   .     @@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@   /%       @@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@#@@@(@@@@@@@@@@@@@@@@@@@@@`}</span>
    );
  }
}

export default CatAsciiSmall;